<template>
  <Modal ref="modal" title="Фильтры" :width="600" :vertical-btns="false">
    <div class="form">
      <div>
        <div class="form">
          <div class="keywords">
            <div class="keywords_title">Ключевые слова</div>
            <div class="keywords_input-wrap">
              <input
                name="new-keyword"
                class="keywords_input"
                type="text"
                placeholder="Ключевое слово в резюме"
                v-model="newKeywordValue"
                @keyup.enter="addKeyword"
              >
              <div
                class="keywords_add-button transparent-button"
                :class="{'disabled': !hasNewKeywordsValue}"
                @click="addKeyword"
              >
                Добавить
              </div>
            </div>
            <div class="keywords_list">
              <div class="keywords_item" v-for="(keyword, index) in keywords" :key="index">
                <div class="keywords_item_value">{{ keyword }}</div>
                <div class="keywords_item_remove" @click="removeKeyword(index)">
                  <div class="icon icon-close"></div>
                </div>
              </div>
            </div>
          </div>
          <v-row class="range-sliders">
            <v-col class="range-slider-container pb-0">
              <div class="form-range-field_title">Возраст</div>
              <v-range-slider
                :max="rangeAgeMax"
                :min="rangeAgeMin"
                v-model="rangeAge"
                :step="1"
                class="mr-4"
                persistent-hint
                hide-details
                height="65"
              >
                <template v-slot:prepend>
                  <span>{{ rangeAge[0] }}</span>
                </template>
                <template v-slot:append>
                  <span>{{ rangeAge[1] }}</span>
                </template>
              </v-range-slider>
            </v-col>
            <v-col class="range-slider-container pb-0">
              <div class="form-range-field_title">Рейтинг</div>
              <v-range-slider
                v-model="rangeRate"
                :max="rangeRateMax"
                :min="rangeRateMin"
                :step="1"
                persistent-hint
                hide-details
                height="65"
              >
                <template v-slot:prepend>
                  <span>{{ rangeRate[0] }}</span>
                </template>
                <template v-slot:append>
                  <span>{{ rangeRate[1] }}</span>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row>
        <v-col>
          <div class="radio-list">
            <ul>
              <li v-for="(item, index) in filtersValue" :key="index">
                <v-checkbox :label="item.name" v-model="item.value" hide-details="auto" :true-value="1" :false-value="0"/>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col>
          <v-select
            v-model="gender"
            :items="genders"
            label="Пол"
            item-text="text"
            item-value="value"
            persistent-hint
          />
<!--          <div class="languages">
            <div class="languages_title">Языки</div>
            <div class="languages_list">
              <div class="languages_item" v-for="(language, key) in languages" :key="key">
                <div class="languages_item_remove" @click="removeLanguage(key)">
                  <div class="icon icon-close"/>
                </div>
                <div class="languages_item_title">
                  {{ allLanguages[key].title }}
                </div>
                <div class="languages_item_value">
                  <Field
                    dir="rtl"
                    type="select"
                    :values="Object.entries(languageValues).map(([value, text]) => ({value, text}))"
                    @change="changeLanguageValue(key, $event.target.value)"
                    :name="`language-${key}`"
                  />
                </div>
              </div>
            </div>
            <div
              class="languages_button transparent-button"
              :class="{'disabled': !Object.keys(notAddedLanguages).length}"
              @click="callLanguagesAddModal"
            >
              Добавить язык
            </div>
          </div>-->
        </v-col>
      </v-row>
    </div>
    <SelectLanguageModal ref="select_language_modal" :not-added-languages="notAddedLanguages" :languages="languageValues" :set-langs="setLanguagesValues"/>
    <template v-slot:actions>
      <v-btn width="150" depressed :ripple="false" color="primary" @click="apply" class="mr-4">Применить</v-btn>
      <v-btn width="150" depressed :ripple="false" @click="reset">Сбросить</v-btn>
    </template>
  </Modal>
</template>

<script>
import SelectLanguageModal from '@/views/candidates/filters/SelectLanguageModal';

export default {
  name: 'CandidatesFiltersModal',
  components: {
    SelectLanguageModal
  },
  props: {
    filterParams: Object,
  },
  emits: ['submit'],
  data() {
    return {
      filter: {},
      newKeywordValue: null,
      keywords: [],
      languages: {},
      selectLanguagesValue: null,
      rangeAge: [14, 65],
      rangeAgeMin: 14,
      rangeAgeMax: 65,
      rangeRate: [0, 5],
      rangeRateMin: 0,
      rangeRateMax: 5,
      gender: '0',

      genders: [
        {
          value: '0',
          text: 'Не важно',
        },
        {
          value: '1',
          text: 'Мужской',
        },
        {
          value: '2',
          text: 'Женский',
        },
      ],
      allLanguages: {
        en: {
          title: 'Английский',
        },
        fr: {
          title: 'Французский',
        },
      },
      languageValues: {
        A1: 'A1 - начальный',
        A2: 'A2 - элементарный',
        B1: 'B1 - средний',
        B2: 'B2 - средне-продвинутый',
        C1: 'C1 - продвинутый',
        C2: 'C2 - в совершенстве',
      },
      filtersValue: [
        {
          name: 'Избранные',
          value: '0'
        },
        /*{name: 'Прикреплен к вакансии', value: 'hasVacancy'},
        {name: 'На испытательном сроке', value: 'probation'},
        {name: 'Вышел на работу', value: 'onWork'},
        {name: 'Отказ', value: 'decline'},*/
      ]
    };
  },
  methods: {
    open() {
      this.setDataFromParams();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    addKeyword() {
      if (!this.hasNewKeywordsValue) return;
      this.keywords.push(this.newKeywordValue);
      this.newKeywordValue = '';
    },
    removeKeyword(index) {
      this.keywords.splice(index, 1);
    },

    removeLanguage(lang) {
      delete this.languages[lang];
      this.languages = { ...this.languages };
      this.setLanguagesValues();
    },
    changeLanguageValue(lang, value) {
      this.languages[lang] = value;
      this.languages = { ...this.languages };
      this.setLanguagesValues();
    },

    setLanguagesValues() {
      this.languages.forEach(([lang, value]) => {
        lang[value] = value;
      });
    },

    callLanguagesAddModal() {
      if (!Object.keys(this.notAddedLanguages).length) return;
      this.$refs.select_language_modal.open();
    },
    getFilterParamsFromData() {
      return {
        query: this.query ? this.query : null,
        favourite: this.filtersValue.find(val=>val.name === 'Избранные').value*1 ? this.filtersValue.find(val=>val.name === 'Избранные').value : null,
        keywords: this.keywords.length ? this.keywords : null,
        languages: Object.keys(this.languages).length ? this.languages : null,
        age_from: !this.rangeAge[0] || this.rangeAge[0] !== this.rangeAgeMin ? this.rangeAge[0] : null,
        age_to: !this.rangeAge[1] || this.rangeAge[1] !== this.rangeAgeMax ? this.rangeAge[1] : null,
        min_rate: !this.rangeRate[0] || this.rangeRate[0] !== this.rangeRateMin ? this.rangeRate[0] : null,
        max_rate: !this.rangeRate[1] || this.rangeRate[1] !== this.rangeRateMax ? this.rangeRate[1] : null,
        gender: this.gender*1 ? this.gender : null,
      };
    },
    setDataFromParams() {
      if (typeof this.filterParams !== 'object' || this.filterParams === null) return;

      this.query = this.filterParams.query || '';
      this.keywords = this.filterParams.keywords || [];
      this.languages = this.filterParams.languages || {};
      this.languages = { ...this.languages };
      this.setLanguagesValues();
      this.rangeAge = [
        this.filterParams.age_from || this.rangeAgeMin,
        this.filterParams.age_to || this.rangeAgeMax,
      ];
      this.rangeRate = [
        this.filterParams.min_rate || this.rangeRateMin,
        this.filterParams.max_rate || this.rangeRateMax,
      ];
      this.gender = this.filterParams.gender || '0';
    },
    apply() {
      this.addKeyword();
      this.submit(this.getFilterParamsFromData());
    },
    reset() {
      this.filter = {};
      this.newKeywordValue = null;
      this.keywords = [];
      this.languages = {};
      this.selectLanguagesValue = null;
      this.rangeAge = [14, 65];
      this.rangeAgeMin = 14;
      this.rangeAgeMax = 65;
      this.rangeRate = [0, 5];
      this.rangeRateMin = 0;
      this.rangeRateMax = 5;
      this.gender = '0';
      this.filtersValue.map((val)=>{
        val.value = '0';
      })
      this.submit(null);
    },
    submit(filterParams) {
      this.$emit('submit', filterParams);
      this.close();
    },
  },
  computed: {
    hasNewKeywordsValue() {
      if (!this.newKeywordValue) return false;
      return !!this.newKeywordValue.replace(/ /g, '');
    },
    notAddedLanguages() {
      return Object.fromEntries(Object.entries(this.allLanguages)
        .filter(([key]) => !Object.hasOwnProperty.call(this.languages, key)));
    },
  },
  mounted() {
    this.$refs.modal.onDOMUpdate = () => {
      this.setLanguagesValues();
    };
  },
};
</script>

<style scoped lang="scss">
.form {
  grid-row-gap: 12px;
}

.modal {
  &::v-deep(.modal-content) {
    width: 640px;
  }
}

.radio-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_item {
    cursor: pointer;
    display: flex;

    .checkbox {
      flex-shrink: 0;
      margin-right: 14px;
    }
  }
}

.keywords {
  &_title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
  }

  &_input-wrap {
    width: 100%;
    padding-left: 12px;
    display: flex;
    align-items: center;
    background: rgba(233, 233, 233, 0.6);
    border-radius: 8px;

    input {
      width: 100%;
      padding: 10px 8px;
      background: transparent;
      font-size: 15px;
      line-height: 22px;
      border: none;
    }
  }

  &_add-button {
    padding: 10px;
    flex-shrink: 0;
    width: auto;
  }

  &_list {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    & > * {
      margin-right: 8px;
      margin-bottom: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_item {
    display: flex;
    border-radius: 5px;
    align-items: center;
    max-width: 80%;
    border: 1px solid var(--color-gray);
    min-height: 30px;

    &_value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 5px;
      font-size: 16px;
      line-height: 16px;
    }

    &_remove {
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      padding: 0 10px;
    }
  }
}

.languages {
  &_title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);

    &_remove {
      cursor: pointer;
      padding-right: 6px;
      align-self: stretch;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-top: 2px;
      }
    }

    &_title {
      position: absolute;
      left: 22px;
    }

    &_value {
      width: 100%;
    }

    &::v-deep(.form-field_input) {
      cursor: pointer;
      border-bottom: none;
    }
  }

  &_button {
    margin-top: 12px;
    width: auto;
    display: inline-block;
  }
}

.modal.languages-modal {
  &::v-deep(.modal-content) {
    width: 300px;
  }
}
</style>
