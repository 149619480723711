<template>
  <div class="page">
    <div class="page-content" @scroll="onScroll" id="page-candidates" ref="page">
      <div class="page-header">
        <div class="page-title flex-grow-1" ref="page_title" id="page-candidates-title">Кандидаты</div>
        <a href="https://rekroo.org/hr/catalog" target="_blank" class="ml-auto mr-3">
          <v-btn outlined depressed>База резюме</v-btn>
        </a>
        <div
          v-if="!isSharedLink"
          class="page-header-button default-button"
          @click="$router.push({name: 'candidatesCreate'})"
        >
          <div class="icon icon-new"></div>
          <span>Новый кандидат</span>
        </div>
      </div>
      <div class="searchbar-wrap">
        <Searchbar name="search" placeholder="Поиск" @input="filterCandidates"/>
        <div class="transparent-button" @click="$refs['filters-modal'].open()">Фильтры
          <span class="filters-count" v-if="filtersCount > 0">{{ filtersCount }}</span>
        </div>
      </div>
      <div class="table-container">
        <v-data-table
          v-model="selectedCandidates"
          :headers="tableHeaders"
          :items="filteredCandidates"
          class="elevation-0 candidates-table"
          no-data-text="Кандидатов нет"
          loading-text="Загрузка кандидатов..."
          :loading="loadingTable"
          loader-height="0"
          :show-select="!isSharedLink"
          item-key="id"
          @click:row="rowClicked"
          id="virtual-scroll-table"
          disable-sort
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.data-table-select="{isSelected,select}">
            <div style="width: 100%; height: 100%; display: flex; padding-left: 10px" @click.stop>
              <v-simple-checkbox :value="isSelected" @input="select($event)"/>
            </div>
          </template>
          <template v-slot:item.name="{item}">
            <CandidateInfoBlock :item="item" class="pr-2"/>
          </template>
          <template v-slot:item.vacancy="{item}">
            <router-link
              class="candidate-vacancy color-blue pr-2"
              :to="{name: 'Vacancy', params: {vacancyId: item.vacancy.id}}"
              v-if="item.vacancy && Number(item.vacancy.id)"
              @click.prevent
            >
              {{ item.vacancy.title }} {{ item.vacancy.company }}
            </router-link>
            <span class="candidate-vacancy empty" v-else @click.stop>Не прикреплен</span>
          </template>
          <template v-slot:item.status="{item}">
            <div v-if="item.vacancy">
              <CandidateFolder :candidate="item" v-if="item.folder"/>
              <CandidateStatus :candidate="item" v-else/>
            </div>
          </template>
          <template v-slot:item.more="{item}" v-if="!isSharedLink">
            <PopoverList :control-buttons="
          item.vacancy?.id ? controlButtons.filter((button)=>withVacancyButtons.includes(button.name)) :
            controlButtons.filter((button)=>withoutVacancyButtons.includes(button.name))" :item="item"/>
          </template>
          <template #no-data>
            <NoResults class="mt-12">
              <template v-slot:title>Кандидатов пока нет</template>
            </NoResults>
          </template>
          <template #loading>
            <Loader height="120"/>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-btn
      v-if="candidates.length > 15"
      @click="scrollToTop"
           depressed rounded :ripple="false" :class="`back-to-top-button`" width="50" height="50">
      <v-icon color="primary">{{ `mdi-arrow-${scrollTop > 200 ? 'up' : 'down'}-circle` }}</v-icon>
    </v-btn>
    <ControlPanel :items="selectedCandidates" :control-buttons="controlButtons.filter((button)=>button.type === 'global')"/>
    <Confirm
      ref="confirm-unbind-candidates"
      message="Открепить выбранных кандидатов?"
      @accept="unbindCandidate"
    />
    <ContactsModal ref="contacts_modal"/>
    <CandidatesFiltersModal ref="filters-modal" @submit="filterCandidates"/>
    <Confirm ref="confirm_remove_modal" message="Вы точно хотите удалить кандидатов(-а)?" @accept="removeCandidates"/>
    <CreateEventModal ref="create_event_modal"/>
  </div>
</template>
<script>
import ControlPanel from '@/components/ControlPanel.vue';
import CandidatesFiltersModal from '@/views/candidates/filters/CandidatesFiltersModal.vue';
import ContactsModal from '@/views/candidate/ContactsModal.vue';
import Confirm from '@/components/Confirm';
import CreateEventModal from '@/views/event/create/CreateEventModal';

export default {
  components: {
    CreateEventModal,
    CandidatesFiltersModal,
    ControlPanel,
    ContactsModal,
    Confirm
  },
  data() {
    return {
      isSharedLink: window.location.href.split('/').includes('shr'),
      scrollTop: 0,
      candidates: [],
      filteredCandidates: [],
      selectedCandidates: [],
      filters: {
        limit: 40,
        page: 0
      },
      tableHeaders: [
        {
          text: 'Кандидат',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Вакансия',
          align: 'start',
          value: 'vacancy',
        },
        {
          text: 'Статус',
          align: 'start',
          value: 'status',
        },
        {
          text: '',
          align: 'end',
          value: 'more',
        },
      ],
      loadingTable: false,
      withVacancyButtons: ['contacts', 'unbind', 'share', 'remove', 'create_interview'],
      withoutVacancyButtons: ['contacts', 'bind', 'share', 'remove', 'create_interview'],
      timeout: null,
      rowHeight: 80,
      headerHeight: 40,
      itemsLengthCurrentPage: 0,
      filtersCount: 0,
    };
  },
  methods: {
    get({
      isUpdated = false,
      normalPages = null,
      normalLimit = null
    } = {}) {
      this.loadingTable = true;
      this.$server.request2('candidate/get', this.filters, (data) => {
        if (this.filters.page > 0) {
          this.candidates = this.candidates.concat(data.response);
          this.filteredCandidates = this.filteredCandidates.concat(data.response);
        } else {
          this.candidates = data.response;
          this.filteredCandidates = data.response;
          this.selectedCandidates = [];
        }
        this.itemsLengthCurrentPage = data.response.length;

        if (isUpdated) {
          this.filters.page = normalPages;
          this.filters.limit = normalLimit;
        }
      }, () => {
      }, () => {
        this.loadingTable = false;
      });
    },
    rowClicked(item) {
      if (this.isSharedLink) {
        this.$router.push(`/shr/${this.$route.params.token}/${item.id}`);
      } else this.$router.push(`/candidates/candidate/${item.id}/`);
    },
    onScroll(e) {
      if (this.loadingTable) return;
      const { scrollTop } = e.target;
      this.scrollTop = scrollTop;
      const rows = Math.ceil((scrollTop - this.headerHeight) / this.rowHeight);
      if (this.filteredCandidates.length - rows < 2 / 3 * this.filters.limit) {
        if (this.itemsLengthCurrentPage > 0) {
          this.filters.page++;
          this.get();
        }
      }
    },
    scrollToTop() {
      this.$refs.page.scrollTo({
        top: this.scrollTop > 200 ? 0 : 1000000,
        behavior: 'smooth'
      });
    },
    unbindCandidate() {
      let candidatesWithVacancy = this.selectedCandidates.filter((candidate) => candidate.vacancy);
      candidatesWithVacancy.forEach((candidate, index) => {
        this.$server.request2(`vacancy/removeCandidate/${candidate.vacancy.id}`, { id: candidate.id }, () => {
          if (index === candidatesWithVacancy.length - 1) this.getCandidatesAfterUpdateRows();
        });
      });
    },
    filterCandidates(value) {
      if (typeof value === 'string') {
        this.filters.query = value;
      } else {
        this.filtersCount = 0;
        this.filters = { ...value };
        let filtersKey = Object.keys(this.filters);
        filtersKey.forEach((key) => {
          if (![null, 0].includes(this.filters[key]) && !['page', 'limit'].includes(key)) {
            this.filtersCount++;
          }
        });
      }
      this.filters.page = 0;
      this.filters.limit = 40;
      let normalPages = this.filters.page;
      let normalLimit = this.filters.limit;
      this.get({
        isUpdated: true,
        normalPages,
        normalLimit
      });
    },
    removeCandidates(ids) {
      ids.forEach((id, index) => {
        this.$server.request2(`candidate/remove/${id}`, {}, () => {
          if (ids.length - 1 === index) {
            this.getCandidatesAfterUpdateRows();
          }
        });
      });
    },
    getCandidatesAfterUpdateRows() {
      let normalPages = this.filters.page;
      let normalLimit = this.filters.limit;
      this.filters.limit = (this.filters.page + 1) * this.filters.limit;
      this.filters.page = 0;
      this.get({
        isUpdated: true,
        normalPages,
        normalLimit
      });
    },
    getCandidatesByToken() {
      this.loadingTable = true;
      this.$server.request2('candidate/getByToken', {
        at: this.$route.params.token,
      }, (data) => {
        this.candidates = data.response;
        this.filteredCandidates = data.response;
        this.loadingTable = false;
      }, ()=>{
        this.loadingTable = false;
      });
    }
  },
  computed: {
    controlButtons() {
      return [
        {
          name: 'contacts',
          text: 'Контакты',
          color: '',
          type: 'menu',
          icon: 'mdi-cellphone',
          handler: (items) => {
            items.forEach((item) => {
              this.$refs.contacts_modal.open(item.id, item.phone);
            });
          },
        },
        {
          name: 'bind',
          text: 'Прикрепить',
          type: 'global',
          icon: 'mdi-pin-outline',
          count: this.selectedCandidates.filter((item) => !item.vacancy).length,
          handler: (items) => {
            this.$eventBus.emit('select-vacancies-modal-open', items.filter((item) => !item.vacancy));
          },
        },
        {
          name: 'share',
          text: 'Поделиться',
          type: 'global',
          icon: 'mdi-share-variant-outline',
          handler: (items) => {
            const ids = items.map((item) => item.id);
            this.$server.request2('candidate/createAccessToken', { ids }, (data) => {
              this.$eventBus.emit('open-share-candidate-modal', {
                token: data.response
              });
            });
          },
        },
        {
          name: 'create_interview',
          text: 'Назначить собеседование',
          icon: 'mdi-calendar',
          handler: (items) => {
            let candidate = { ...items[0] };
            this.$refs.create_event_modal.open({type: '0', candidate});
          },
        },
        {
          name: 'unbind',
          text: 'Открепить',
          type: 'global',
          icon: 'mdi-pin-off-outline',
          color: 'red_color',
          count: this.selectedCandidates.filter((item) => item.vacancy).length,
          handler: (items) => {
            this.selectedCandidates = items;
            this.$refs['confirm-unbind-candidates'].open();
          },
        },
        {
          name: 'remove',
          text: 'Удалить',
          color: 'red_color',
          type: 'global',
          icon: 'mdi-delete-outline',
          handler: (items) => {
            let ids = items.map((item) => item.id);
            this.$refs.confirm_remove_modal.open({ vacancyIds: ids }); //vacancyIds - старое название, везде лень переименовывать. Так вышло :(
          },
        },
      ];
    }
  },
  created() {
    this.$eventBus.on('candidates-added-to-vacancy', () => this.getCandidatesAfterUpdateRows());
  },
  mounted() {
    if (this.isSharedLink) this.getCandidatesByToken();
    else this.get();
  },
  beforeDestroy() {
    this.$eventBus.off('candidates-added-to-vacancy');
  },
};
</script>
<style scoped lang="scss">
#page-candidates {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .page-header {
    justify-content: flex-start;
  }

  .page-header.hidden {
    display: none;
  }

  .table-container {
    display: flex;
    flex-direction: column;
  }

  .searchbar-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    background: #fff;

    &::v-deep(.searchbar) {
      width: 100%;
      margin-bottom: 0;
    }

    .transparent-button {
      padding: 10px;
      width: auto;
      white-space: nowrap;
      flex-shrink: 0;
      position: relative;
    }

    .filters-count {
      position: absolute;
      right: 0;
      top: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #333333;
      font-size: 8px;
      line-height: 12px;
      color: #FFFFFF;
    }

  }
}

.candidate-vacancy {
  display: block;
  font-weight: 600;
  color: rgba(var(--page-color-main-rgb), .25);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}

</style>
