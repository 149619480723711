<template>
  <Modal ref="modal" classes="languages-modal" title="Языки">
    <div class="form">
      <div class="radio-list">
        <v-checkbox
          v-for="(language, key) in notAddedLanguages" :key="key"
          v-model="lang"
          :value="key"
          :label="language.title"
        />
      </div>
    </div>
    <template v-slot:actions>
      <v-btn depressed color="primary" :ripple="false" @click="addLanguage">Выбрать</v-btn>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'SelectLanguageModal',
  props: ['notAddedLanguages', 'languages'],
  emits: ['set-langs'],
  data() {
    return {
      lang: '',
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    addLanguage() {
      this.$emit('set-langs', this.notAddedLanguages);
      if (!this.selectLanguagesValue) return;
      this.languages[this.selectLanguagesValue] = Object.keys(this.languageValues)[0];
      this.selectLanguagesValue = null;
      this.languages = { ...this.languages };
      this.setLanguagesValues();
      this.close();
    },
  }
};
</script>

<style scoped>

</style>